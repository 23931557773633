// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import { Coordinate } from '../../../../../types/Coordinate'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'

interface Props {
  initial: Coordinate
  onChange: (items: object) => void
}

const markerHelper = (max = 0) => {
  let result = []

  for (let i = 0; i < max; i++) {
    result.push({ value: i })
  }

  return result
}

export const PreviewSlider = ({ onChange, initial }: Props) => {
  const classes = useStyles()
  const [sliderValues, setSliderValues] = useState<Coordinate>(initial)

  const angleMarks = markerHelper(35)
  const liftMarks = [{ value: 5 }, { value: 8 }, { value: 11 }]
  const speedMarks = markerHelper(7)

  const handleSliderChange = (value: number | number[], item: string) => {
    setSliderValues((currentState: Coordinate) => {
      return { ...currentState, ...{ [item]: value } }
    })
  }

  useEffect(() => {
    onChange(sliderValues)
  }, [sliderValues, onChange])

  return (
    <>
      <Grid item xs={12} md={4} style={{ paddingRight: '25px' }}>
        <Grid container spacing={2}>
          <Grid item xs={5} md={4}>
            <Typography
              gutterBottom
              style={{
                marginBottom: '35px',
              }}
              className={'rendering-control-text'}
            >
              Hubstellung
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.controlText}>
              {5}
            </Typography>
          </Grid>
          <Grid item xs>
            <Slider
              defaultValue={1}
              min={5}
              step={3}
              valueLabelDisplay="on"
              marks={liftMarks}
              max={11}
              onChangeCommitted={(event, value) =>
                handleSliderChange(value, 'lift')
              }
            ></Slider>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.controlText}>
              {11}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={5} md={4}>
            <Typography
              gutterBottom
              style={{
                marginBottom: '35px',
              }}
              className={'rendering-control-text'}
            >
              Gradstellung
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.controlText}>
              {1}
            </Typography>
          </Grid>
          <Grid item xs>
            <Slider
              defaultValue={1}
              step={1}
              min={1}
              valueLabelDisplay="on"
              marks={angleMarks}
              max={35}
              onChangeCommitted={(event, value) =>
                handleSliderChange(value, 'angle')
              }
            ></Slider>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.controlText}>
              {35}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={5} md={4}>
            <Typography
              gutterBottom
              style={{
                marginBottom: '35px',
              }}
              className={'rendering-control-text'}
            >
              Geschwindigkeit
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.controlText}>
              {1}
            </Typography>
          </Grid>
          <Grid item xs>
            <Slider
              defaultValue={1}
              min={1}
              step={1}
              valueLabelDisplay="on"
              marks={speedMarks}
              max={7}
              onChangeCommitted={(event, value) =>
                handleSliderChange(value, 'speed')
              }
            ></Slider>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" className={classes.controlText}>
              {7}
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container spacing={2}>
          <Grid item xs={5} md={4}>
            <Typography
              gutterBottom
              style={{
                marginBottom: '35px',
              }}
            >
              Schnittdarstellung
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{1}</Typography>
          </Grid>
          <Grid item xs>
            <Slider
              defaultValue={1}
              min={1}
              step={1}
              valueLabelDisplay="on"
              marks={cutMarks}
              max={7}
              onChangeCommitted={(event, value) =>
                handleSliderChange(value, 'cut')
              }
            ></Slider>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">{7}</Typography>
          </Grid>
        </Grid>*/}
      </Grid>
    </>
  )
}

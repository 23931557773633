import { createStyles } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles(() => {
  return createStyles({
    graphImage: {
      maxWidth: '100%',
      height: 'auto',
      borderTopLeftRadius: '25px',
      borderTopRightRadius: '25px',
    },
  })
})

// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useState } from 'react'
import { PublicFrame } from '../components/common/ui/organism/PublicFrame/PublicFrame'
import { PreviewImage } from '../components/common/ui/organism/PreviewImage/PreviewImage'
import { PreviewSlider } from '../components/common/ui/organism/PreviewSlider/PreviewSlider'
import { Coordinate } from '../types/Coordinate'

export const HomePage = () => {
  const [sliderValues, setSliderValues] = useState<Coordinate>({
    angle: 1,
    lift: 5,
    speed: 1,
    cut: 1,
  })

  const handlePreviewSliderChange = (items: any) => {
    setSliderValues(items)
  }

  return (
    <PublicFrame>
      <PreviewSlider
        initial={sliderValues}
        onChange={handlePreviewSliderChange}
      ></PreviewSlider>
      <PreviewImage baseName="name" coordinates={sliderValues}></PreviewImage>
    </PublicFrame>
  )
}

// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import { CardMedia } from '@material-ui/core'
import { Coordinate } from '../../../../../types/Coordinate'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import monitorTop from '../../../../../static/img/monitor-top.png'
import monitorBottom from '../../../../../static/img/monitor-bottom.png'
import { useStyles } from './styles'

interface Props {
  baseName: string
  coordinates: Coordinate
}

export const PreviewImage = ({ baseName, coordinates }: Props) => {
  const classes = useStyles()
  const handleError = (event: any) =>
    (event.target.src =
      'https://pof.aiflow.de/img/Bild_Hub_5_mm_Winkel_0_Geschw_0.svg')

  return (
    <Grid item style={{ display: 'block', maxWidth: '100%' }}>
      <Box
        style={{
          display: 'block',
          width: '1096',
          height: '565',
          maxWidth: '100%',
        }}
        width={1096}
        className={'graph-rendering'}
      >
        <Box className={'monitor-top'}>
          <img src={monitorTop} />
        </Box>
        <CardMedia
          component="img"
          width="1096"
          height="565"
          src={`https://pof.aiflow.de/img/Bild_Hub_${coordinates.lift}_mm_Winkel_${coordinates.angle}_Geschw_${coordinates.speed}.svg`}
          alt="Fake"
          onError={handleError}
          className={classes.graphImage}
        ></CardMedia>
        <Box className={'monitor-bottom'}>
          <img src={monitorBottom} />
        </Box>
      </Box>
    </Grid>
  )
}

// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import './App.css'
import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom'
import { HomePage } from './pages/Homepage'

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={HomePage}></Route>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  )
}
export { App }

import { createStyles } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles(() => {
  return createStyles({
    controlText: {
      color: '#fff',
    },
  })
})

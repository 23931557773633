// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { ReactNode } from 'react'
import Grid from '@material-ui/core/Grid'

interface Props {
  children: ReactNode
}

export const PublicFrame = ({ children }: Props) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '3rem',
        }}
      >
        <Grid container alignItems={'center'} justify={'center'}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}
